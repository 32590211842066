<style src="./apply-mobile.component.scss" lang="scss"></style>
<template>
  <div class="apply-mobile mt-20">
    <div class="row">
      <div class="col-12">
        <accordion-component>
          <v-card class="mb-10" v-for="(item, index) in data" :key="index">
            <accordion-item-component @click="item.isOpen = !item.isOpen">
              <template v-slot:title>
                <div class="information">
                  <div class="information__description" v-if="item.structureName?.length>0">
                    {{ item.structureName }}
                  </div>
                  <div class="information__description" v-else>
                    {{ item.feedbackTypeName }}
                  </div>
                  <div class="information__title mt-6 ellipsis" v-if="item.serviceName?.length>0"
                       :class="{'ellipsis__active':!item.isOpen}">
                    {{ item.serviceName }}
                  </div>
                  <div class="information__title mt-6 ellipsis" v-else>
                    {{ item.feedbackTypeName }}
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="information mt-36">
                  <div class="information__title">
                    Müraciət tarixi:
                  </div>
                  <div class="information__description">
                    {{ item.date }}
                  </div>
                </div>
              </template>
            </accordion-item-component>
          </v-card>
        </accordion-component>
      </div>
    </div>
  </div>
</template>
<script src="./apply-mobile.component.ts"></script>
