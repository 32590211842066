import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { ref } from 'vue';

@Options({
  name: 'search-advanced-component',
  components: {
    Datepicker
  }
})

export default class UserSearchAdvancedComponent extends Vue {
  public date: any = null;
  setup() {
    const date = ref();
    const textInputOptions = ref({
      format: 'MM.dd.yyyy'
    })

    return {
      date,
      textInputOptions,
    }
  }
  // public filter:object = {
  // }
  // public clearFilter():void {
  // }
}
