<style src="./search-advanced.component.scss" lang="scss"> </style>

<template>
  <div class="user-search__advanced">
    <form action="" class="form">
        <div class="col-12">
          <div class="form__group">
            <label class="v-label mb-4" >Qurumlar</label>
            <div class="v-select">
              <select>
                <option value="0">0</option>
              </select>
            </div>
          </div>
          <div class="form__group">
            <label class="v-label mb-4">Kateqoriya</label>
            <div class="v-select">
              <select>
                <option value="0">0</option>
              </select>
            </div>
          </div>
          <div class="row">
          <div class="col-12 datepickers">
            <div class="col-6  datepicker__input mt-20 mb-20">
              <Datepicker v-model="date" placeholder="tarixdən ..." textInput :textInputOptions="textInputOptions" />
            </div>
            <div class="col-6 datepicker__input mt-20 mb-20 ml-10 ">
              <Datepicker v-model="date" placeholder="tarixədək ..." textInput  :textInputOptions="textInputOptions"/>
            </div>
          </div>
          </div>
        <div class="col-12">
          <v-divider/>
          <div class="button-group">
            <v-btn>Filterləri Sıfırla</v-btn>
            <v-btn color="dark">Axtar</v-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./search-advanced.component.ts"></script>
