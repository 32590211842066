import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import SearchTagComponent from '@/core/layouts/public/components/search-tag/search-tag.component.vue';
import SearchAdvancedComponent from '@/core/layouts/public/components/search-advanced/search-advanced.component.vue';
import UserSearchAdvancedComponent
    from '@/pages/user-account/components/user-search-advanced/search-advanced.component.vue';

@Options({
    name: 'search-component',
    components: {
        SearchTagComponent,
        SearchAdvancedComponent,
        UserSearchAdvancedComponent,
    }
})

export default class SearchComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public word: string = '';
    public tags: Array<any> = [
        {
            id: 1,
            name: 'IMEI qeydiyyat'
        },
        {
            id: 2,
            name: 'Rabitə'
        },
        {
            id: 3,
            name: 'Telefon çəkilişi'
        },
        {
            id: 4,
            name: 'İnternet'
        },
        {
            id: 5,
            name: 'Küçələrin axtarışı'
        },
        {
            id: 6,
            name: 'Mobil nömrə'
        }
    ];
    public isAdvanced: boolean = false;
    public searchValue: string = '';
    public loaderCheck = false;
    public searchResult: Array<any> = [];

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.home.user) {
                this.locale = res.home.user;
            }
        });
    }

    public searchData() {
        this.$emit('searchWord', this.word);
    }

    public searchFilter() {
        this.loaderCheck = true;
    }
}
