<style src="./search.component.scss" lang="scss"> </style>

<template>
  <div
      :class="{'search-active':isAdvanced || searchResult.length}"
      class="search">
    <div class="search__input mb-20">
      <form @submit.prevent="searchData">
        <input
            :placeholder="locale.search"
            v-model="word"
            @input="searchFilter"
            class="v-input" type="text" >
        <button class="search__button">
          <img src="@/assets/images/icons/search.svg" alt="search">
        </button>
      </form>

<!--      <div-->
<!--          @click="isAdvanced =!isAdvanced"-->
<!--          class="search__advanced-icon">-->
<!--        <img src="@/assets/images/icons/filter.svg" alt="filter">-->
<!--        Ətraflı axtarış-->
<!--      </div>-->
    </div>
<!--    <search-example-components :examples="searchResult" :loader="loaderCheck" v-if="searchResult.length"/>-->
<!--    <search-tag-component :tags="tags"  />-->
<!--    <transition appear enter-active-class="animate__animated animate__fadeIn"-->
<!--                leave-active-class="animate__animated animate__fadeOut"-->
<!--                :duration="500" mode="out-in"-->
<!--    >-->
<!--&lt;!&ndash;      <search-advanced-components v-if="isAdvanced" />&ndash;&gt;-->
<!--      <user-search-advanced-component v-if="isAdvanced" />-->
<!--    </transition>-->
  </div>
</template>

<script src="./search.component.ts"></script>
