import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TableComponent from '@/core/shared/table/table.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';
import SearchComponent from '@/pages/user-account/components/user-search/search.component.vue';

@Options({
    name: 'apply-mobile-component',
    components: {
        TableComponent,
        AccordionComponent,
        AccordionItemComponent,
        SearchComponent
    },
    props: {
        data: {
            type: Array,
        },
    }
})

export default class ApplyMobileComponent extends Vue {
    private store = store;
    public numbers: IGetNumber[] = [];
    public locale$: any = locale;
    public locale: any = null;
    public requestLocalHistory = true;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
            }
        });
    }

    get selectNumber(): any {
        if (this.store.getters._getSelect && this.requestLocalHistory) {
            this.$emit('changeSelectNumber', this.store.getters._getSelect.id);
            this.requestLocalHistory = false;
        }
        return this.store.getters._getSelect;
    }

    public changeSelectNumber(info: any): void {
        this.store.commit('SET_DEFAULT_SELECT', {
            id: info,
            value: info
        });
        this.$emit('changeSelectNumber', this.selectNumber.id);
    }

}
